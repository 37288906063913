<template>
  <div class="navbar">
    <div class="col-md-12" style="width: 100%">
      <div v-if="isCollapsed" class="dropdown" style="float: left">
        <span
          class="collapse-icon showMenu"
          :class="{ 'rotate-180': collapsed }"
          @click="displaySideBar"
        >
          <i class="fas fa-bars"></i>
        </span>
      </div>

      <div class="flex justify-content-end">
        <div
          v-if="
            permissao.hab_visualizar_ci_secretaria ||
            permissao.hab_responder_ci_secretaria ||
            permissao.hab_responder_ci_secretaria
          "
        >
          <p style="color: #fff" v-if="visualizacao === 'usuario'">
            Utilizando a minha caixa
          </p>
          <p style="color: #fff" v-else>Utilizando a caixa do secretário</p>
        </div>
        <div
          v-if="
            permissao.hab_visualizar_ci_secretaria ||
            permissao.hab_responder_ci_secretaria ||
            permissao.hab_responder_ci_secretaria
          "
          style="color: #fff"
          class="ml-3"
        >
          |
        </div>
        <div class="dropdown">
          <button
            type="button"
            class="btn btn-primary dropdown-toggle nav-button pt-0"
            data-bs-toggle="dropdown"
            style="background-color: #0000; border-color: #0000"
          >
            Olá, {{ nome }}
          </button>
          <ul class="dropdown-menu dropdown-menu-itens">
            <li>
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="goToRoute"
                >Perfil</a
              >
            </li>
            <li
              v-if="
                permissao.hab_visualizar_ci_secretaria ||
                permissao.hab_responder_ci_secretaria ||
                permissao.hab_responder_ci_secretaria
              "
            >
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="trocaModoViz"
                >{{ modo_viz }}</a
              >
            </li>

            <li>
              <a class="dropdown-item" href="javascript:void(0)" @click="sair"
                >Sair</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  collapsed,
  toggleSidebar,
  sidebarWidth,
} from "@/components/sidebar/state.ts";
import { useMensagemStore } from "@/store/mensagem/mensagemStore";
import { useCriptoStore } from "@/store/store";

export default defineComponent({
  name: "NavbarComponent",
  props: { isCollapsed: { type: Boolean } },
  data() {
    return {
      nome:
        sessionStorage.getItem("nome") == null
          ? "User"
          : sessionStorage.getItem("nome"),
      change: "none",
      total: 0,
      pagina: 1,
      porPagina: 20,
      mensagens: [],
      totalCiAberta: 0,
      timer: "",
      timer2: "",
      qtdmsg: 0,
      naoLidas: [],
      permissao: {},
      modo_viz: "Minha Caixa",
      modos_viz: ["Minha Caixa", "Caixa do Secretário"],
      visualizacao: sessionStorage.getItem("modo"),
    };
  },
  setup() {
    const mensagemStore = useMensagemStore();

    return {
      mensagemStore,
      collapsed,
      toggleSidebar,
      sidebarWidth,
    };
  },
  methods: {
    trocaModoViz() {
      var modo = "usuario";
      if (this.modo_viz === "Caixa do Secretário") {
        modo = "secretario";
      }
      sessionStorage.setItem("modo", modo);
      location.href = "/";
    },
    async CiAberta() {
      await this.mostrarTodos();
      let msgAbertas = this.mensagens.filter(
        (e) => e.situacao === "Aberta" || e.situacao === "Em Andamento"
      );
      this.totalCiAberta = msgAbertas.length;
    },
    async mostrarTodos() {
      // contar msg que ainda estao abertas
      var dat = await this.mensagemStore.todasMsgForamLidas();
      this.mensagens = dat.lista;
    },
    goToRoute() {
      this.$router.push({ name: "meus-dados-usuario" });
    },
    goToRouteMensagem(id) {
      $router.push({
        name: "visualizar-mensagem",
        params: { id: id },
      });
    },
    ajustarSize(size) {
      this.$emit("ajustarSize", size);
    },
    displaySideBar() {
      //$(".hideShowSideBar").css({display:'block'});
      document.getElementById("hideShowSideBar").style.display = "block";
      this.ajustarSize("250px");
    },
    sair() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("nome");
      sessionStorage.removeItem("permissao");

      location.href = "/autenticacao/login";
    },
  },
  async beforeMount() {
    var modo = String(sessionStorage.getItem("modo"));
    this.modo_viz = modo == "usuario" ? "Caixa do Secretário" : "Minha Caixa";

    const criptoStore = useCriptoStore();
    var permissoes = await criptoStore.decryptBack(
      sessionStorage.getItem("permissao")
    );
    var p = JSON.parse(permissoes);
    this.permissao = p;
  },
  Unmounted() {
    clearInterval(this.timer);
  },
});
</script>

<style lang="scss" scoped>
.p-inputtext {
  font-size: 2px !important;
}
.p-dropdown-item {
  font-size: 2px !important;
  // height: 30px;
}
</style>

<style scoped>
body {
  font-family: "Arial", Serif;
  background-color: #f5f5f5;
  overflow-x: hidden;
}
.nav-button {
  margin-right: 68px;
}
.navbar {
  background-color: #032750;
  /*overflow: hidden;*/
  height: 63px;
}

@media only screen and (max-width: 800px) {
  .navbar {
    height: 100px;
  }
}

.aviso_msg {
  background-color: #918c8c;
  padding: 3px 9px;
  border-radius: 12px;
}

.navbaropen {
  background-color: #3b5998;
  /*overflow: hidden;*/
  height: 63px;
  margin-left: 250px;
}

.navbar a {
  float: left;
  display: block;
  /*color: #f2f2f2;*/
  text-align: center;
  padding: 4px 9px;
  text-decoration: none;
  font-size: 17px;
}

.navbar ul {
  margin: 8px 0 0 0;
  list-style: none;
}

.navbar a:hover {
  background-color: #ddd;
  color: #000;
}

.side-nav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  opacity: 0.9;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.3s;
}

.sidenavopen {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  opacity: 0.9;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.3s;
}

.side-nav a {
  padding: 10px 10px 10px 30px;
  text-decoration: none;
  font-size: 22px;
  color: #ccc;
  display: block;
  transition: 0.3s;
}

.side-nav a:hover {
  color: #fff;
}

.side-nav .btn-close {
  position: absolute;
  top: 0;
  right: 22px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left 0.3s;
  padding: 20px;
  overflow: hidden;
  width: 100%;
}

.mainopen {
  transition: margin-left 0.3s;
  padding: 20px;
  overflow: hidden;
  width: 100%;
  margin-left: 250px;
}

nav {
  transition: margin-left 0.3s;
}

.bar {
  display: block;
  height: 5px;
  width: 35px;
  background: #000;
  margin: 5px auto;
}

.midopen {
  width: 0;
}
.bar {
  transition: all 0.3s ease;
}
.topopen {
  transform: translateY(10px) rotateZ(45deg);
}
.botopen {
  transform: translateY(-10px) rotateZ(-45deg);
}

.showMenu {
  font-size: 18px;
  color: #fff;
  padding: 31px;
  padding-right: 10px;
}
.showMenu:hover {
  cursor: pointer;
}

.text-color {
  color: #fff !important;
}

.dropdown-menu-itens {
  top: 30px !important;
  --bs-dropdown-min-width: 7rem !important;
  --bs-btn-focus-shadow-rgb: 0, 0 0 !important;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(3 169 244 / 0%) !important;
}
</style>
