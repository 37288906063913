import { defineStore } from "pinia";
import axios from "axios";

export const formatadorMsgStore = defineStore("formatar", {
  state: () => ({
  }),

  getters: {
   
  },

  actions: {
   
    //mostrar todas as msg do sistema
    addWordBreak(str: string)  {

      // A function to check if a string is empty or not
      // Create a temporary DOM element
      var tempElement = document.createElement('div');
      tempElement.innerHTML = str;
      //verificar se tem alguma tabela
      const tabelas = tempElement.querySelectorAll("table");
      if (!tabelas.length) {
        return str;
      }

      tabelas.forEach(tabela => {
        //Pegar todos <tr> da tabela
        const rows = tabela.querySelectorAll("td");
        if (!rows.length) {
          return;
        }
        // Passar por todos <td> da tabela e adicionar o style
        rows.forEach(row => {
          const currentStyle = row.getAttribute("style");
          var newStyle = currentStyle + "word-break: break-word;";
          row.setAttribute("style", newStyle);
        });
      });

      var modifiedHtmlString = tempElement.outerHTML;
      return modifiedHtmlString;
    }
  },
});


  
    
