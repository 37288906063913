
import { defineComponent } from "vue";
import { FilterMatchMode } from "primevue/api";
import { Form } from "vee-validate";
import { useMensagemStore } from "@/store/mensagem/mensagemStore";
import { formatadorMsgStore } from "@/store/mensagem/formatarStore";
import { useSecretariaStore } from "@/store/secretaria/secretariaStore";
import { useCriptoStore } from "@/store/store";

import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import IDestinatario from "@/interfaces/IDestinatario";
import * as Yup from "yup";
import { useUsuarioStore } from "@/store/usuario/usuarioStore";
import IPermissao from "@/interfaces/IPermissao";
import ModalSenhaAssinadorVue from "./ModalSenhaAssinador.vue";

export default defineComponent({
  name: "NovaMensagemDialogComponent",
  inject: ["dialogRef"],
  props: {
    id: { type: Number },
    modo: { type: String },
    position: { type: String },
    displayPosition: { type: Boolean },
  },
  components: {
    Form,
    editor: Editor,
    ModalSenhaAssinadorVue,
  },
  data() {
    return {
      senhaAssinador: "",
      visible: false,
      permissoes: {} as IPermissao,
      usuario: {
        assinatura: "",
        email: "",
        id: 0,
        login: "",
        matricula: "",
        permissao_id: 0,
        secretarias: [],
        setores: [],
        vinculos: [
          {
            setor: { id: "" as any },
          },
        ],
      },
      selecionarSecOuSet: {} as any,
      selecionarTipoCi: 0 as any,
      tipoSecOuSet: [
        { id: 1, nome: "Secretarias" },
        { id: 2, nome: "Setores" },
      ],
      tipoCi: [
        { id: 1, nome: "Normal" },
        { id: 2, nome: "Retroativa" },
      ],
      secDoUsuario: "" as any,
      idUsuario: Number(sessionStorage.getItem("id")),
      ehSecretario: Number(sessionStorage.getItem("eleEhSecretario")) as any,
      loadedDest: true,
      labelAll: "Adicionar Todas as Secretarias",
      mensagem: {
        id: 0,
        identificador: null as any,
        situacao: "",
        data_envio: "",
        destinatarios: [],
        anexos: [] as any[],
        prioridade: "normal",
        titulo: "",
        conteudo: "",
        prazo_resposta: "" as any,
        setor_envio: 0,
        secretaria_escolhida: {},
        ci_reservada: false,
        usuario_ci_reservada_id: 0,
        cadastro_usuario_id: 0,
        senha_assinador: "" as any,
        remetente: {
          vinculos: [{ secretaria: { nome: "" } }],
        },
      },
      secretarias: [] as any[],
      setores: [] as any[],
      prioridades: ["normal", "urgente"],
      destinatario: {
        mensagem_id: 0,
        secretaria_id: 0,
        setor_id: 0,
        usuario_id: 0,
        secretaria: {},
        setor: {
          id: 0,
          nome: "Secretário",
          setores_relacionados: [] as any[],
        } as any,
      } as IDestinatario,
      filtro: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      errors: {
        titulo: "" as string,
        conteudo: "" as string,
      } as any,
      isLoading: false,
      editorConfig: {
        plugins:
          "directionality table charmap advlist lists textpattern noneditable charmap quickbars",
        imagetools_cors_hosts: ["picsum.photos"],
        menubar: "edit insert format tools table",
        toolbar:
          "bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | forecolor backcolor removeformat | charmap | ltr rtl",
        language: "pt_BR",
        toolbar_sticky: true,
        branding: false,
        contextmenu_never_use_native: false,
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: false,
        importcss_append: true,
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 300,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quicktable",
        quickbars_insert_toolbar: false,
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        contextmenu: "link imagetools table",
        content_style: "body { font-family:Arial,sans-serif; font-size:10pt }",
      },
      secSelec: [] as any,
      setSelec: [] as any,
      secFiltrado: [] as any,
      setFiltrado: [] as any,
      displayPosition: false,
      errosAnexo: [] as any[],
      mostrarApenasOsSetores: false,
      qualSetorEnviar: 0 as any,
      minDate: "" as any,
      ciReservas: null as any,
      periodo: "" as any,
      periodos: [] as any,
      selectDay: "" as any,
      todasSecs: [],
      porQualSecEnviar: {},
      mensagemAviso: "",
      abrevNomeSec: "",
      impr: "",
      baseurl: axios.defaults.baseURL as any,
      loadingButton: false,
      modalAssinatura: false,
      msgAssinar: null,
      senhaSig: "",
      passwordFailed: "",
      errorColor: "",
      flagAssina: true,
      flagAssinarDepois: false,
      idMsg: 0,
    };
  },
  watch: {
    selecionarTipoCi() {
      if (this.selecionarTipoCi == 2) {
        if (
          this.periodo != undefined &&
          this.periodo != "" &&
          this.ciReservas != null
        ) {
          this.mensagem.id = this.ciReservas[this.periodo][0].id;
          this.mensagem.identificador =
            this.ciReservas[this.periodo][0].identificador;
          this.mensagem.ci_reservada = true;
          this.mensagem.usuario_ci_reservada_id = this.idUsuario;
          this.alteraAnexos();
        }
      } else {
        this.mensagem.id = sessionStorage.getItem("mensagem_rascunho") as any;
        this.mensagem.identificador = null;
        this.mensagem.ci_reservada = false;
        this.mensagem.usuario_ci_reservada_id = 0;
        this.alteraAnexos();
      }
    },
  },
  methods: {
    async editar() {
      this.loadingButton = true;
      var keys = Object.keys(this.errors);
      for (var i = 0; i < keys.length; i++) {
        this.validate(keys[i]);
      }
      if (!this.flag) {
        this.loadingButton = false;
        return false;
      }

      if (
        this.usuario.setores.length > 1 &&
        (this.qualSetorEnviar == undefined || this.qualSetorEnviar == 0)
      ) {
        this.$toast.error("Selecione de qual setor você deseja enviar!");
        this.mensagemAviso = "Selecione de qual setor você deseja enviar!";
        this.loadingButton = false;
        return false;
      } else {
        if (this.usuario.setores.length > 1 && this.qualSetorEnviar != 0) {
          // this.qualSetorEnviar = this.qualSetorEnviar.id;
          this.qualSetorEnviar = this.qualSetorEnviar.setor.id;
        }
      }

      if (this.usuario.setores.length == 1 && this.qualSetorEnviar == 0) {
        var temp = this.usuario.setores as any;
        this.qualSetorEnviar = temp[0];
      }

      if (
        this.selecionarTipoCi === 2 &&
        Object.keys(this.porQualSecEnviar).length === 0
      ) {
        this.mensagemAviso = "Selecione de qual secretaria você deseja enviar";
        this.loadingButton = false;

        return false;
      }

      if (this.secSelec.length === 0) {
        this.mensagemAviso = "Selecione pelo menos 1 destinatário";
        this.loadingButton = false;

        return false;
      }

      if (this.selecionarTipoCi === 2) {
        var mes = this.dateConvert(this.periodo);
        var ano = this.periodo.split("/")[1];
        this.mensagem.data_envio =
          ano + "-" + mes + "-" + this.selectDay + " 00:00:00";
        this.destinatario.mensagem_id = this.ciReservas[this.periodo][0].id;

        this.mensagem.secretaria_escolhida = this.porQualSecEnviar;
      } else {
        this.destinatario.mensagem_id = this.mensagem.id;
        var hoje = new Date();
        var parts = hoje.toLocaleString("pt").split(" ");
        var dat =
          parts[0].split("/")[2] +
          "-" +
          parts[0].split("/")[1] +
          "-" +
          parts[0].split("/")[0];
        this.mensagem.data_envio = dat.replace(",", "") + " " + parts[1];
        // this.mensagem.data_envio = this.mensagem.data_envio.replace(",", "");
      }

      this.mensagem.setor_envio = this.qualSetorEnviar;
      // this.mensagem.setor_envio = this.qualSetorEnviar.setor.id;

      for (let i = 0; i < this.secSelec.length; i++) {
        if (this.secSelec[i].secretaria_id == undefined) {
          this.destinatario.secretaria_id = this.secSelec[i].id;
          this.destinatario.setor_id = this.destinatario.setor.id;
          if (this.procuraDuplicidade(this.destinatario)) {
            var res: any = null;
            res = await this.mensagemStore.cadastrarDestinatario(
              this.destinatario
            );
            if (res.code == "200") {
              this.mensagem.destinatarios = res.novos_destinatarios;
            }
          }
        } else if (this.secSelec[i].secretaria_id != undefined) {
          this.destinatario.secretaria_id = this.secSelec[i].secretaria_id;
          this.destinatario.setor_id = this.secSelec[i].id;

          if (this.procuraDuplicidade(this.destinatario)) {
            var res: any = null;
            res = await this.mensagemStore.cadastrarDestinatario(
              this.destinatario
            );
            if (res.code == "200") {
              this.mensagem.destinatarios = res.novos_destinatarios;
            }
          }
        }
      }

      if (this.mensagem.titulo.length > 280) {
        this.$toast.error("O título é muito grande!");
        this.mensagemAviso = "O título é muito grande!";
        this.loadingButton = false;

        return false;
      }

      if (this.mensagem.destinatarios.length > 0) {
        this.mensagem.senha_assinador = this.senhaSig;

        // var hoje = new Date();
        // var parts = hoje.toLocaleString("pt").split(" ");
        // var dat =
        //   parts[0].split("/")[2] +
        //   "-" +
        //   parts[0].split("/")[1] +
        //   "-" +
        //   parts[0].split("/")[0];
        // this.mensagem.data_envio = dat + " " + parts[1];

        var res: any = null;
        res = await this.mensagemStore.editarMensagem(this.mensagem);
        if (res.code == "200") {
          this.$toast.success("Mensagem editada com sucesso.");
        }
      } else {
        this.$toast.error("Adicione pelo menos um destinatário.");
        this.mensagemAviso = "Adicione pelo menos um destinatário";
        this.loadingButton = false;
      }
    },
    fecharModal() {
      this.visible = false;
    },
    zeraBotoes() {
      this.porQualSecEnviar = {};
      this.secSelec = [];
    },

    async gerarPdf(dados: any) {
      this.loadingButton = true;
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      } as any;
      let hoje = new Date();
      let parts = hoje.toLocaleDateString("pt", options);

      let nomeSec = this.mensagem.remetente.vinculos[0].secretaria.nome;
      const char = nomeSec.split("");
      if (nomeSec === "SJBPREV") {
        this.abrevNomeSec =
          char[0] + char[1] + char[2] + char[3] + char[4] + char[5] + char[6];
      } else if (nomeSec === "Controle Interno") {
        this.abrevNomeSec = nomeSec;
      } else if (nomeSec === "Procuradoria") {
        this.abrevNomeSec = nomeSec;
      } else if (nomeSec === "Licitação") {
        this.abrevNomeSec = nomeSec;
      } else if (nomeSec === "Gabinete da Prefeita") {
        this.abrevNomeSec = "Gab. Prefeito(a)";
      } else if (nomeSec === "Protocolo") {
        this.abrevNomeSec = "Protocolo";
      } else if (nomeSec === "Secretaria Municipal de Cultura") {
        this.abrevNomeSec = "Secult";
      } else if (nomeSec === "Almoxarifado e Patrimônio") {
        this.abrevNomeSec = "Alm. e Patri.";
      } else if (nomeSec === "Contas - Almoxarifado e Patrimônio") {
        this.abrevNomeSec = "Contas - Alm. e Patri.";
      } else if (nomeSec === "Representante da Escola de Contas TCE-RJ") {
        this.abrevNomeSec = "Rem";
      } else if (nomeSec === "Coordenadoria de Auditoria e Controle Interno") {
        this.abrevNomeSec = "CACI";
      } else if (nomeSec === "Fundessan") {
        this.abrevNomeSec = "Fundessan";
      } else if (nomeSec === "Ouvidoria Geral") {
        this.abrevNomeSec = "Ouvidoria Geral";
      } else {
        this.abrevNomeSec =
          char[0] +
          char[1] +
          char[2] +
          char[14] +
          char[15] +
          char[16] +
          char[17];
      }

      const setorSec = {
        id: 0,
        nome: "Secretário",
      };
      const sec = this.secretarias.filter((e) => e.id === this.secDoUsuario);

      if (!dados.assinatura) {
        dados.assinatura = this.usuario.assinatura;
      }

      dados.data_envio = parts;
      dados.conteudo = this.mensagem.conteudo;
      dados.nomeSecretaria = this.abrevNomeSec;
      dados.destinatarios = [];
      if (this.secSelec.length === 0) {
        this.mensagemAviso = "Selecione pelo menos 1 destinatário";
        this.loadingButton = false;
        return false;
      }
      if (this.selecionarSecOuSet === 1) {
        for (let i = 0; i < this.secSelec.length; i++) {
          dados.identificador = "000/0000";
          dados.destinatarios.push(this.secSelec[i]);
          dados.destinatarios[i].setor = setorSec;
          dados.destinatarios[i].secretaria = {
            nome: this.secSelec[i].nome,
            id: this.secSelec[i].id,
            status: this.secSelec[i].status,
          };
        }
      } else if (this.selecionarSecOuSet === 2) {
        for (let i = 0; i < this.secSelec.length; i++) {
          dados.identificador = "000/0000";
          dados.destinatarios.push(this.secSelec[i]);
          dados.destinatarios[i].secretaria = sec[0];
          dados.destinatarios[i].setor = {
            nome: this.secSelec[i].nome,
            id: this.secSelec[i].id,
            status: this.secSelec[i].status,
          };
        }
      }

      if (this.qualSetorEnviar != 0) {
        dados.setorRemetente = this.qualSetorEnviar.setor;
      }

      dados.conteudo = this.formatadorMSG.addWordBreak(dados.conteudo);

      const allSecsCrip = await this.criptoStore.decryptBack(
        sessionStorage.getItem("todasSecs")
      );
      const allSecs = JSON.parse(allSecsCrip);
      if (allSecs.length - 1 === dados.destinatarios.length) {
        dados.todosDest = true;
      } else {
        dados.todosDest = false;
      }

      if(dados.assinador.setorRemetente == null && dados.assinador.vinculos.length === 1){
        if (dados.assinador.vinculos[0].setor_id === 0) {
          dados.assinador.setorRemetente = dados.assinador.vinculos[0].secretaria;
          dados.assinador.setorRemetente.id = 0;
        }else{
          dados.assinador.setorRemetente = dados.assinador.vinculos[0].setor;
        }
      }else if(dados.assinador.vinculos.length > 1){
        dados.assinador.setorRemetente = dados.setorRemetente;
      }
      try {
        await axios.post("mensagem-pdf", dados).then((response) => {
          this.impr = response.data;
          window.open(this.baseurl.replace("api/", "") + this.impr, "_blank");
        });
        this.loadingButton = false;
      } catch (e) {
        this.loadingButton = false;
      }
    },

    dateConvert(mes: string) {
      let data = {
        Janeiro: "01",
        Fevereiro: "02",
        Março: "03",
        Abril: "04",
        Maio: "05",
        Junho: "06",
        Julho: "07",
        Agosto: "08",
        Setembro: "09",
        Outubro: "10",
        Novembro: "11",
        Dezembro: "12",
      } as any;
      mes = mes.split("/")[0];
      return data[mes];
    },
    daysInMonth(m: any, y: any) {
      // m is 0 indexed: 0-11
      switch (m) {
        case 1:
          return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
        case 8:
        case 3:
        case 5:
        case 10:
          return 30;
        default:
          return 31;
      }
    },
    isValid() {
      if (this.periodo == "") {
        this.periodo = "Janeiro/2023";
      }
      var d = this.selectDay;
      var m = this.dateConvert(this.periodo);
      var y = this.periodo.split("/")[1];
      var res = m >= 0 && m < 12 && d > 0 && d <= this.daysInMonth(m, y);
      if (!res) {
        this.selectDay = "";
      }
    },
    prioridadeMsg() {
      if (this.mensagem.prioridade == "normal")
        this.mensagem.prioridade = "urgente";
      else this.mensagem.prioridade = "normal";
    },

    procurarSec() {
      let filteredItems = [];

      if (this.selecionarSecOuSet === 1) {
        this.secSelec = [];
        for (let i = 0; i < this.secretarias.length; i++) {
          if (this.secretarias[i].status == true) {
            let sec = this.secretarias[i];

            if (this.ehSecretario) {
              if (sec.id != this.secDoUsuario) {
                filteredItems.push(sec);
              }
            } else {
              filteredItems.push(sec);
            }
          }
        }
      } else if (this.selecionarSecOuSet === 2) {
        this.secSelec = [];
        let query2 = "";
        for (let i = 0; i < this.setores.length - 1; i++) {
          if (this.setores[i].status == true) {
            let set = this.setores[i];
            if (set.id != this.usuario.setores[0]) {
              filteredItems.push(set);
            }
          }
        }
      }

      this.secFiltrado = filteredItems;
    },

    procurarSet(event: any) {
      let query = event.query;
      let filteredItems = [];
      for (let i = 0; i < this.setores.length; i++) {
        let set = this.setores[i];
        if (set.nome.toLowerCase().indexOf(query.toLowerCase()) != -1) {
          filteredItems.push(set);
        }
      }
      this.setFiltrado = filteredItems;
    },

    processaArquivos(event: any) {
      this.errosAnexo = [] as any[];
      for (var i = 0; i < event.files.length; i++) {
        var formData = new FormData();
        formData.append("file", event.files[i]);
        formData.append("mensagem_id", String(this.mensagem.id));
        this.enviaAnexo(formData);
      }
    },

    async enviaAnexo(formData: any) {
      var res: any = null;
      res = await this.mensagemStore.enviaAnexo(formData);
      if (res.code == "200") {
        if (this.mensagem.anexos == undefined) {
          this.mensagem.anexos = [];
        }
        this.mensagem.anexos.push(res.objeto);
      } else {
        this.errosAnexo.push(res.original.erro);
      }
    },

    async alteraAnexos() {
      var res: any = null;
      for (var i = 0; i < this.mensagem.anexos.length; i++) {
        this.mensagem.anexos[i].mensagem_id = this.mensagem.id;
        res = await this.mensagemStore.alteraAnexo(this.mensagem.anexos[i]);
        /*if (res.code == "200") {

          } else {
            this.errosAnexo.push(res.original.erro);
          }*/
      }
    },

    async removerAnexo(id: number) {
      var res: any = null;
      res = await this.mensagemStore.removeAnexo(id);
      if (res.code == "200") {
        this.mensagem.anexos = res.anexos;
        this.$toast.success("Anexo removido com sucesso.");
      } else {
        this.$toast.error(res.validacao);
      }
    },

    validate(field: string) {
      this.schema
        .validateAt(field, this.mensagem)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[field] = err.message;
        });
    },

    procuraDuplicidade(obj: any) {
      var temp = {} as any;
      for (var i = 0; i < this.mensagem.destinatarios.length; i++) {
        temp = this.mensagem.destinatarios[i];
        if (
          temp.secretaria_id == obj.secretaria_id &&
          temp.setor_id == obj.setor_id
        ) {
          return false;
        }
      }
      return true;
    },

    async salvaMensagem() {
      var res: any = null;
      res = await this.mensagemStore.editarMensagem(this.mensagem);
      if (res.code == "200") {
        this.$toast.success("Mensagem salva com sucesso.");
      }
    },

    closeModalSenha() {
      this.visible = false;
      // this.$emit("visible", false);
    },
    trocaVisualizacaoPermissao() {
      if (this.flagAssinarDepois) {
        this.ehSecretario = true;

        this.secretariaStore.buscarTodos().then((v) => {
          //this.todasSecs=v
          this.secretarias = v;
          this.procurarSec();
        });
      } else {
        this.ehSecretario = false;

        this.secretariaStore.buscarTodos().then((v) => {
          let secretariasQueEleFazParte = "" as any;
          secretariasQueEleFazParte = sessionStorage.getItem(
            "secrearias_validas_enc"
          );
          let idSec = JSON.parse(secretariasQueEleFazParte);
          var dadosDeSecretaria = v;
          this.secretarias = [];
          for (let i = 0; i < dadosDeSecretaria.length; i++) {
            if (idSec[0] == dadosDeSecretaria[i].id) {
              this.secretarias.push(dadosDeSecretaria[i]);
              break;
            }
          }
          this.procurarSec();
        });
      }
    },

    async enviaMensagem(opcao: number) {
      // var flagAssina = true;
      if (opcao == 2) {
        if (this.senhaSig != "") {
          if (this.senhaSig.length < 6) {
            this.flagAssina = false;
            this.passwordFailed = "A senha tem no mínimo 6 dígitos";
            this.errorColor = "p-invalid";
            this.$toast.error("A senha tem no mínimo 6 dígitos");
            return;
          } else {
            this.flagAssina = true;
          }
        } else if (this.senhaSig === "") {
          this.flagAssina = false;
          this.passwordFailed = "Digite a senha de assinatura.";
          this.errorColor = "p-invalid";
          this.$toast.error("Digite a senha de assinatura.");
          return;
        } else {
          this.errorColor = "";
          this.flagAssina = true;
        }
      } else {
        this.senhaSig = "";
      }

      if (this.flagAssina) {
        this.loadingButton = true;
        var keys = Object.keys(this.errors);
        for (var i = 0; i < keys.length; i++) {
          this.validate(keys[i]);
        }
        if (!this.flag) {
          this.loadingButton = false;
          return false;
        }

        if (
          this.usuario.setores.length > 1 &&
          (this.qualSetorEnviar == undefined || this.qualSetorEnviar == 0)
        ) {
          this.$toast.error("Selecione de qual setor você deseja enviar!");
          this.mensagemAviso = "Selecione de qual setor você deseja enviar!";
          this.loadingButton = false;
          return false;
        } else {
          if (this.usuario.setores.length > 1 && this.qualSetorEnviar != 0) {
            // this.qualSetorEnviar = this.qualSetorEnviar.id;
            this.qualSetorEnviar = this.qualSetorEnviar.setor.id;
          }
        }

        if (this.usuario.setores.length == 1 && this.qualSetorEnviar == 0) {
          var temp = this.usuario.setores as any;
          this.qualSetorEnviar = temp[0];
        }

        if (
          this.selecionarTipoCi === 2 &&
          Object.keys(this.porQualSecEnviar).length === 0
        ) {
          this.mensagemAviso =
            "Selecione de qual secretaria você deseja enviar";
          this.loadingButton = false;

          return false;
        }

        if (this.secSelec.length === 0) {
          this.mensagemAviso = "Selecione pelo menos 1 destinatário";
          this.loadingButton = false;

          return false;
        }

        if (this.selecionarTipoCi === 2) {
          var mes = this.dateConvert(this.periodo);
          var ano = this.periodo.split("/")[1];
          this.mensagem.data_envio =
            ano + "-" + mes + "-" + this.selectDay + " 00:00:00";
          this.destinatario.mensagem_id = this.ciReservas[this.periodo][0].id;

          this.mensagem.secretaria_escolhida = this.porQualSecEnviar;
        } else {
          this.destinatario.mensagem_id = this.mensagem.id;
          var hoje = new Date();
          var parts = hoje.toLocaleString("pt").split(" ");
          var dat =
            parts[0].split("/")[2] +
            "-" +
            parts[0].split("/")[1] +
            "-" +
            parts[0].split("/")[0];
          this.mensagem.data_envio = dat.replace(",", "") + " " + parts[1];
          // this.mensagem.data_envio = this.mensagem.data_envio.replace(",", "");
        }

        this.mensagem.setor_envio = this.qualSetorEnviar;
        // this.mensagem.setor_envio = this.qualSetorEnviar.setor.id;

        for (let i = 0; i < this.secSelec.length; i++) {
          if (this.secSelec[i].secretaria_id == undefined) {
            this.destinatario.secretaria_id = this.secSelec[i].id;
            this.destinatario.setor_id = this.destinatario.setor.id;
            if (this.procuraDuplicidade(this.destinatario)) {
              var res: any = null;
              res = await this.mensagemStore.cadastrarDestinatario(
                this.destinatario
              );
              if (res.code == "200") {
                this.mensagem.destinatarios = res.novos_destinatarios;
              }
            }
          } else if (this.secSelec[i].secretaria_id != undefined) {
            this.destinatario.secretaria_id = this.secSelec[i].secretaria_id;
            this.destinatario.setor_id = this.secSelec[i].id;

            if (this.procuraDuplicidade(this.destinatario)) {
              var res: any = null;
              res = await this.mensagemStore.cadastrarDestinatario(
                this.destinatario
              );
              if (res.code == "200") {
                this.mensagem.destinatarios = res.novos_destinatarios;
              }
            }
          }
        }

        if (this.mensagem.titulo.length > 280) {
          this.$toast.error("O título é muito grande!");
          this.mensagemAviso = "O título é muito grande!";
          this.loadingButton = false;

          return false;
        }

        if (this.mensagem.destinatarios.length > 0) {
          this.mensagem.senha_assinador = this.senhaSig;
          if (this.flagAssinarDepois) {
            this.mensagem.cadastro_usuario_id = this.usuario.id;
            this.mensagem.senha_assinador = null;
          }

          // var hoje = new Date();
          // var parts = hoje.toLocaleString("pt").split(" ");
          // var dat =
          //   parts[0].split("/")[2] +
          //   "-" +
          //   parts[0].split("/")[1] +
          //   "-" +
          //   parts[0].split("/")[0];
          // this.mensagem.data_envio = dat + " " + parts[1];
          if (this.mensagem.cadastro_usuario_id != 0) {
            this.mensagem.setor_envio = 0;
          }
          var res: any = null;
          try {
            res = await this.mensagemStore.enviaParaAprovacao(this.mensagem);
          } catch (error) {}

          if (res.code === 402) {
            this.passwordFailed = res.validacao;
            this.flagAssina = false;
          }

          if (res.code == "200") {
            sessionStorage.removeItem("mensagem_rascunho");
            sessionStorage.setItem(
              "mensagem_rascunho",
              res.mensagem_rascunho.id
            );
            this.mensagemAviso = "";
            this.mensagem = res.mensagem_rascunho;
            this.mensagem.cadastro_usuario_id = 0;
            this.secSelec = [];
            this.setSelec = [];
            this.selectDay = "" as any;
            this.porQualSecEnviar = {};
            this.visible = false;
            this.senhaSig = "";
            this.flagAssinarDepois = false;
            this.$emit("closePosition");
            this.$toast.success("Mensagem enviada sucesso.");
            var that = this;
            setTimeout(function () {
              if (that.$route.name === "listar-enviadas")
                window.location.reload();
            }, 400);
            this.loadingButton = false;
          } else {
            this.$toast.error(res.validacao);
            this.loadingButton = false;
          }
        } else {
          this.$toast.error("Adicione pelo menos um destinatário.");
          this.mensagemAviso = "Adicione pelo menos um destinatário";
          this.loadingButton = false;
        }
      }
    },
  },

  computed: {
    flag(): boolean {
      var result = this.errors.titulo == "" && this.errors.conteudo == "";
      return result;
    },
  },

  setup() {
    const schema = Yup.object().shape({
      titulo: Yup.string().required("O título é obrigatório"),
      conteudo: Yup.string().required("Escreva algo no corpo da mensagem"),
      senha: Yup.string().required("A senha é obrigatória"),
    });

    const mensagemStore = useMensagemStore();
    const secretariaStore = useSecretariaStore();
    const usuarioStore = useUsuarioStore();
    const formatadorMSG = formatadorMsgStore();
    const criptoStore = useCriptoStore();
    return {
      schema,
      mensagemStore,
      secretariaStore,
      usuarioStore,
      formatadorMSG,
      criptoStore,
    };
  },
  async beforeMount() {
    this.isLoading = true;
    const criptoStore = useCriptoStore();
    var p = await criptoStore.decryptBack(sessionStorage.getItem("permissao"));
    this.permissoes = JSON.parse(p);
    var temp = {
      id: 0,
      nome: "Secretário",
      secretaria_id: 0,
      status: true,
      setores_relacionados: [] as any[],
    };

    let dadosDeSecretaria = await this.secretariaStore.buscarTodos();
    this.secretarias = [];

    if (this.permissoes.hab_envio_reservado) {
      this.todasSecs = dadosDeSecretaria;
    }

    let secretariasQueEleFazParte = "" as any;
    secretariasQueEleFazParte = sessionStorage.getItem(
      "secrearias_validas_enc"
    );

    let idSec = JSON.parse(secretariasQueEleFazParte);
    this.secDoUsuario = Number(idSec);
    temp.secretaria_id = idSec[0];
    if (!this.ehSecretario) {
      for (let i = 0; i < dadosDeSecretaria.length; i++) {
        if (idSec[0] == dadosDeSecretaria[i].id) {
          this.secretarias.push(dadosDeSecretaria[i]);
          break;
        }
      }
    } else {
      this.secretarias = dadosDeSecretaria;
    }

    //pegar os setores das sec que o usuário faz parte
    var userSec = "";
    if (sessionStorage.getItem("secrearias_validas_enc") != null) {
      userSec = sessionStorage.getItem("secrearias_validas_enc") as string;
    }
    var osecs = JSON.parse(userSec) as any;

    var secs = (await this.secretariaStore.buscarTodos()) as any[];
    var tsecs = [] as any[];
    for (var i = 0; i < secs.length; i++) {
      if (osecs.includes(secs[i].id) && secs[i].setores.length > 0) {
        tsecs.push(secs[i]);
      }
    }
    this.setores = [];
    if (tsecs.length > 0) {
      this.setores = tsecs[0].setores;
    }
    this.setores.push(temp);
    this.destinatario.setor = temp;

    var id = 0 as any;
    if (this.modo == "nova") {
      var id = sessionStorage.getItem("mensagem_rascunho") as any;
    } else {
      id = this.id;
    }
    if (id != 0) {
      this.mensagem = await this.mensagemStore.getOne(id);

      if (this.mensagem.destinatarios == undefined) {
        this.mensagem.destinatarios = [];
      } else {
        var tempsec = [] as any[];
        for (let i = 0; i < this.mensagem.destinatarios.length; i++) {
          let sec = this.mensagem.destinatarios[i] as any;
          tempsec.push(sec.secretaria);
        }
        if (tempsec.length < this.secretarias.length - 1 && this.ehSecretario) {
          this.loadedDest = true;
        }
      }

      if (this.mensagem.anexos == undefined) {
        this.mensagem.anexos = [];
      }

      this.mensagem.prazo_resposta = 0;
    }

    this.usuario = await this.usuarioStore.getOne(this.idUsuario);
    this.selecionarSecOuSet = this.tipoSecOuSet[0].id;

    if (this.mensagem.cadastro_usuario_id != 0) {
      this.flagAssinarDepois = true;
    }
    // this.qualSetorEnviar = this.usuario.vinculos[0]

    let data = await this.mensagemStore.obtemCisReservadasDisponiveis();
    this.ciReservas = data.cis_disponiveis;
    this.periodos = Object.keys(this.ciReservas);
    this.periodo = this.periodos[0];

    this.selecionarTipoCi = this.tipoCi[0].id;
    this.procurarSec();
    this.isLoading = false;
    const todasSecs = criptoStore.encryptGo(JSON.stringify(dadosDeSecretaria));
    sessionStorage.setItem("todasSecs", todasSecs);
  },
});
