import { defineStore } from "pinia";
import axios from "axios";
import ISecretaria from "@/interfaces/ISecretaria";

interface State {
  secret_temp: ISecretaria;
}

export const useSecretariaStore = defineStore("secretaria", {
  state: () => ({
    lista: [],
    loading: false,
    secret_temp: {} as ISecretaria,
  }),

  getters: {
    getSecretaria(state) {
      return state.lista;
    },
  },

  actions: {
    async ItemTemporario(p: any) {
      try {
        (this.secret_temp = {} as ISecretaria), (this.secret_temp = p);
      } catch (error) {}
    },
    async buscarTodos() {
      try {
        this.loading = true;
        const data = await axios.get("/secretarias");
        for (const el of data.data) {
          el.gerencia = el.gerencia ? true : false;
        }
        this.lista = data.data;
        this.loading = false;
        return data.data;
      } catch (error) {
        this.loading = false;
        alert(error);
      }
    },
    async mudarStatusGerencia(id: any, status: any) {
      try {
        const data = await axios.put(
          "secretaria/gerencia/" + id + "/" + Number(status)
        );
        return data.data;
      } catch (error) {}
    },
    async getOne(id: any) {
      try {
        const data = await axios.get("secretarias/" + id);
        return data.data;
      } catch (error) {}
    },
    async cadastrar(dados: any) {
      try {
        const data = await axios.post("/secretarias", dados);
        return data;
      } catch (error) {}
    },

    async editar(dados: any) {
      const data = await axios.put("/secretarias/" + dados.id, dados);
      return data;
    },
    async excluir(id: Number) {
      try {
        const data = await axios.delete("/secretarias/" + id);
        this.buscarTodos();
        return data.data;
      } catch (error) {}
    },
  },
});
