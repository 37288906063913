import axios from "axios";

/* Desenvolvimento */
// axios.defaults.baseURL = "http://127.0.0.1:8011/api/";

// axios.defaults.baseURL = "https://api-ci-sjb.portalgov.info/api/";
axios.defaults.baseURL = "https://api-ci-sjbl.portalgov.info/api/";

// rota dev
// axios.defaults.baseURL = "https://dev-api-ci-sjb.portalgov.info/api/";
// axios.defaults.baseURL = "https://testeci.portalgov.info/api/";

axios.defaults.headers.common["Authorization"] =
  "" + sessionStorage.getItem("token");

export const axiosConfig = () => {
  axios.interceptors.response.use(
    (config) => config,
    async (error) => {
      if (error.response.status === 401) {
        sessionStorage.removeItem("token");
        location.href = "/autenticacao/login";
      }
      return Promise.reject(error);
    }
  );
};
