<template>
  <div id="hideShowSideBar" class="sidebar" :style="{ width: sidebarWidth }">
    <pm-Toast position="top-center" />
    <div>
      <span
        class="collapse-icon"
        :class="{ 'rotate-180': collapsed }"
        @click="closeSideBar"
      >
        <!--<i class="pi pi-times"></i>-->
        <i class="fa-solid fa-bars-staggered"></i>
      </span>
      <h2 style="text-align: center">
        <img
          style="height: 30px"
          v-if="collapsed"
          src="@/assets/logoCopia.png"
          alt="logo"
          class="ml-2"
        />
        <img
          style="height: 70px"
          v-else
          src="@/assets/logoCopia.png"
          alt="logo"
          class="ml-2"
        />
      </h2>
    </div>

    <div>
      <!-- <div
        class="field col-12 md:col-3"
        v-if="
          permissao.hab_visualizar_ci_secretaria ||
          permissao.hab_responder_ci_secretaria ||
          permissao.hab_responder_ci_secretaria
        "
      >
        <label style="margin-bottom: 7px">Modo de Visualização:</label>
        <pm-Dropdown
          v-model="modo_viz"
          :options="modos_viz"
          @change="trocaModoViz"
        />
      </div> -->

      <div v-if="novaMensagem">
        <pm-Button
          v-if="collapsed"
          class="p-button-raised p-button-text"
          icon="fas fa-pen"
          @click="openPosition('bottomright')"
        ></pm-Button>
        <pm-Button
          v-else
          style="color: white !important"
          class="p-button-raised p-button-text"
          label="Escrever"
          icon="fas fa-pen"
          @click="openConfirmationModal"
        ></pm-Button>
        <!-- <pm-Button
          v-else
          class="p-button-raised p-button-text"
          label="Escrever"
          icon="fas fa-pen"
          @click="openPosition('bottomright')"
        ></pm-Button> -->
      </div>

      <DropdownComponent
        v-for="(item, index) in menuTree"
        :key="index"
        :data="item.children"
        :label="item.label"
        :icon="item.icon"
        :depth="0"
        :smallMenu="false"
        :route="item.route"
        @click="fecharSideBarMobile(item.label)"
      />
    </div>

    <NovaMensagemDialogComponent
      v-model:visible="displayPosition"
      :position="position"
      @close-position="closePosition"
      :id="id"
      modo="nova"
    ></NovaMensagemDialogComponent>

    <pm-Dialog
      header="Aviso:"
      v-model:visible="displayConfirmation"
      :style="{ width: '650px' }"
      :modal="true"
      :closable="false"
    >
      <div class="confirmation-content">
        <!-- <i class="pi pi-exclamation-triangle mr-3" style="font-size: 1.8rem" /> -->
        <span>
          <!-- Para que não ocorra quebra de linhas e espaçamentos irregulares no
          corpo da mensagem, NÃO cole textos oriundos de softwares de edição de
          textos e planilhas (Ex.:word, excel, libreoffice e etc.), mas poderá
          copiar do próprio editor do sistema, também não é necessário colocar
          "DE", "PARA" e data de envio, pois são incluídas automaticamente assim
          que a CI é enviada. -->
          Para que não ocorra quebra de linhas e espaçamentos irregulares no
          corpo da mensagem, NÃO cole textos oriundos de word e excel ou
          similares, mas poderá copiar do próprio editor do sistema, também não
          é necessário colocar "DE", "PARA" e data de envio, pois são incluídas
          automaticamente assim que a CI é enviada.
        </span>
      </div>
      <template #footer>
        <pm-Button
          label="Ok!"
          @click="closeConfirmation"
          class="p-button-outlined p-button-info p-button-sm"
          autofocus
        />
      </template>
    </pm-Dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { collapsed, toggleSidebar, sidebarWidth } from "./state";

import DropdownComponent from "./DropdownComponent.vue";
import NovaMensagemDialogComponent from "@/components/dialog/NovaMensagemDialogComponent.vue";

import { useCriptoStore } from "@/store/store";
import { useMensagemStore } from "@/store/mensagem/mensagemStore";
import { useUsuarioStore } from "@/store/usuario/usuarioStore";

export default defineComponent({
  name: "SidebarComponent",
  props: {},
  components: {
    DropdownComponent,
    NovaMensagemDialogComponent,
  },
  setup() {
    const mensagemStore = useMensagemStore();
    const usuarioStore = useUsuarioStore();
    return {
      mensagemStore,
      collapsed,
      toggleSidebar,
      sidebarWidth,
      usuarioStore,
    };
  },
  data() {
    return {
      novaMensagem: false,
      smallSize: false,
      displayPosition: false,
      displayConfirmation: false,
      position: "center",
      menuTree: [],
      aviso: 0,
      id:
        sessionStorage.getItem("mensagem_rascunho") == null
          ? 0
          : parseInt(sessionStorage.getItem("mensagem_rascunho")),
      usuario: sessionStorage.getItem("id"),
      permissao: {},
      modo_viz: "Minha Conta",
      modos_viz: ["Minha Conta", "Como Secretário"],
      isSecretario: sessionStorage.getItem("eleEhSecretario"),
    };
  },
  computed: {},
  methods: {
    trocaModoViz() {
      var modo = "usuario";
      if (this.modo_viz == "Como Secretário") {
        modo = "secretario";
      }
      sessionStorage.setItem("modo", modo);
      location.href = "/";
    },
    openConfirmationModal() {
      this.aviso = Number(sessionStorage.getItem("aviso"));
      if (this.aviso === 0) {
        this.displayConfirmation = true;
      } else if (this.aviso > 0) {
        this.openPosition("bottomright");
      }
    },
    closeConfirmation() {
      this.aviso = Number(sessionStorage.getItem("aviso"));
      this.aviso += 1;
      sessionStorage.setItem("aviso", String(this.aviso));
      this.displayConfirmation = false;

      this.openPosition("bottomright");
    },
    async validaEnviaCi() {
      let data = await this.usuarioStore.getOne(this.usuario);
      if (
        data.assinatura === null ||
        data.assinatura === "" ||
        data.assinatura === undefined
      ) {
        return false;
      } else {
        return true;
      }
    },
    fecharSideBarMobile(item) {
      if (item == "Configurações") return false;

      let width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width <= 800) {
        this.closeSideBar();
      }
    },
    ajustarSize(size) {
      this.$emit("ajustarSize", size);
    },
    closeSideBar() {
      document.getElementById("hideShowSideBar").style.display = "none";
      this.ajustarSize("0px");
    },
    async leuTodasAsMsg() {
      // var dat = await this.mensagemStore.todasMsgForamLidas();
      var id = sessionStorage.getItem("id");
      var dat = await this.mensagemStore.buscarMensagensLeitura(
        0,
        0,
        1,
        0,
        0,
        id
      );
      if (dat.leuTodasMsg) {
        return true;
      } else {
        return false;
      }
    },
    async openPosition(position) {
      let resposta = await this.leuTodasAsMsg();
      let novaCi = await this.validaEnviaCi();
      if (!resposta) {
        this.$toast.warning(
          "Você deve ler todas as mensagens não lidas da Caixa de Entrada antes de enviar mensagens!"
        );
        this.position = position;
        this.displayPosition = true;
      } else if (!novaCi) {
        this.position = position;
        this.displayPosition = true;
        // this.$toast.warning("É necessário uma assinatura para o envio de CI");
      } else {
        this.position = position;
        this.displayPosition = true;
      }
    },
    closePosition() {
      this.displayPosition = false;
    },
  },
  async beforeMount() {
    let data_user = await this.usuarioStore.getOne(this.usuario);
    sessionStorage.setItem("aviso", String(this.aviso));

    var modo = String(sessionStorage.getItem("modo"));
    this.modo_viz = modo == "usuario" ? "Minha Conta" : "Como Secretário";

    var main = [
      // { label: "Dashboard", icon: "fas fa-columns", route: "area-de-trabalho" },
    ];

    var base = {};
    const criptoStore = useCriptoStore();
    var permissoes = await criptoStore.decryptBack(
      sessionStorage.getItem("permissao")
    );
    var p = JSON.parse(permissoes);
    this.permissao = p;

    var modo = sessionStorage.getItem("modo");
    var secId = sessionStorage.getItem("usecretario_id");
    if (
      (this.permissao.hab_visualizar_ci_secretaria ||
        this.permissao.hab_responder_ci_secretaria ||
        this.permissao.hab_responder_ci_secretaria) &&
      modo == "secretario"
    ) {
      this.usuario = secId;
    }

    var secs = JSON.parse(sessionStorage.getItem("secrearias_validas_enc"));

    //var p = JSON.parse(sessionStorage.getItem("permissao"));

    if (!p) {
      await this.$router.push({ name: "login" });
    }

    if (
      !p.hab_log_mensagens_todas ||
      (p.hab_log_mensagens_todas && secs[0] != 0)
    ) {
      main.push({
        label: "Caixa de Entrada",
        icon: "fas fa-inbox",
        route: "listar-entrada",
      });
    }

    if (
      p.hab_envio &&
      (!p.hab_log_mensagens_todas ||
        (p.hab_log_mensagens_todas && secs[0] != 0))
    ) {
      this.novaMensagem = true;
      main.push({
        label: "CI Enviadas",
        icon: "fas fa-envelope-circle-check",
        route: "listar-enviadas",
      });

      // if (
      //   p.hab_escrever_ci_para_assinar ||
      //   data_user.setores.length == 0 ||
      //   data_user.setores == null
      // ) {
      main.push({
        label: "Documentos para Assinar",
        icon: "fas fa-note-sticky",
        route: "listar-mensagens-para-assinar",
      });

      //}

      // base = { label: "Mensagens", icon: "fas fa-gear", children: [] };
      // base.children.push({
      //   label: "Mensagens Enviadas",
      //   route: "listar-enviadas",
      // });

      // base.children.push({
      //   label: "Caixa de Entrada",
      //   route: "listar-entrada",
      // });

      // if (p.hab_envio) {
      //   main.push({
      //     label: "Nova Mensagem",
      //     icon: "fas fa-pen",
      //     route: "nova-mensagem",
      //   });

      //   // base.children.push({ label: "Nova Mensagem", route: "nova-mensagem" });
      // }
      if (p.hab_admin_secretaria) {
        // main.push({
        //   label: "Aprovação Envio Secretaria",
        //   icon: "fas fa-inbox",
        //   route: "listar-aprovacao-sec-dest",
        // });
        // main.push({
        //   label: "Aprovação Envio Setor",
        //   icon: "fas fa-inbox",
        //   route: "listar-aprovacao-setores",
        // });
        // base.children.push({
        //   label: "Aprovar Mensagens para Secretaria Destinatária",
        //   route: "listar-aprovacao-sec-dest",
        // });
        // base.children.push({
        //   label: "Aprovar Mensagens para Setores Destinatários",
        //   route: "listar-aprovacao-setores",
        // });
      }

      // main.push(base);
    }

    // if (p.hab_relatorio_vencida) {
    //   main.push({
    //     label: "Relatório CI Vencidas",
    //     icon: "fas fa-envelope-circle-check",
    //     route: "listar-relatorios-ci-vencidas",
    //   });
    // }

    // if (this.isSecretario === "1" || modo=='secretario') {
    //   main.push({
    //     label: "Encaminhamentos",
    //     icon: "fas fa-envelope-circle-check",
    //     route: "listar-encaminhamentos-para-assinar",
    //   });
    // }

    if (p.hab_log_mensagens_todas) {
      main.push({
        label: "Mensagens Enviadas",
        icon: "fas fa-rectangle-list",
        route: "listar-todas-mensagens",
      });
    }
    if (p.hab_relatorio_vencida) {
      base = { label: "Relatórios", icon: "fas fa-scroll", children: [] };
      if (p.hab_relatorio_vencida) {
        base.children.push({
          label: "CI Vencidas",
          route: "listar-relatorios-ci-vencidas",
        });
      }
      main.push(base);
    }

    if (p.hab_permissao || p.hab_usuario) {
      base = { label: "Configurações", icon: "fas fa-gear", children: [] };
      if (p.hab_permissao) {
        base.children.push({
          label: "Consultar Permissões",
          icon: "fas fa-list",
          route: "listar-permissao",
        });
      }
      if (p.hab_secretaria) {
        base.children.push({
          label: "Lista de secretarias",
          // icon: "fa-solid fa-landmark-flag",
          route: "lista-de-secretarias",
        });
      }
      if (p.hab_setor) {
        base.children.push({
          label: "Lista de setores",
          // icon: "fa-solid fa-landmark",
          route: "lista-de-setores",
        });
      }
      if (p.hab_usuario) {
        base.children.push({
          label: "Lista de usuários",
          // icon: "fa-solid fa-users",
          route: "listar-usuarios",
        });
      }
      // if (p.hab_admin_secretaria) {
      //   base.children.push({
      //     label: "Relatório de visualizações",
      //     icon: "fa-solid fa-file",
      //     route: "relatorio-visualizacao",
      //   });
      // }

      main.push(base);
    }

    this.menuTree = main;
  },
});
</script>

<style scoped>
.sidebar {
  color: #fff;
  background-color: var(--sidebar-bg-color);

  float: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-y: auto;
  bottom: 0;
  padding: 0.5rem;

  transition: 0.3s ease;

  display: flex;
  flex-direction: column;
}

.collapse-icon {
  position: absolute;
  right: 0;
  padding: 0.75rem;

  color: rgba(255, 255, 255, 0.7);

  transition: 0.2s linear;
}
.collapse-icon:hover {
  cursor: pointer;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
</style>

<style lang="scss" scoped>
::v-deep() {
  .cor-fonte {
    color: black !important;
  }
  .p-button-label {
    // color: white;
  }
  .p-button-icon {
    color: white;
  }
  .p-button {
    width: 100%;
    margin-bottom: 20px;
    background-color: #ca0003 !important;
  }
  .p-button:hover {
    background-color: #ff0004 !important;
    transition: 0.3s;
  }
}
</style>
