
import { defineComponent } from "vue";
import { FilterMatchMode } from "primevue/api";
import { Form } from "vee-validate";
import { useMensagemStore } from "@/store/mensagem/mensagemStore";
import { useSecretariaStore } from "@/store/secretaria/secretariaStore";
import { useCriptoStore } from "@/store/store";

import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import IDestinatario from "@/interfaces/IDestinatario";
import * as Yup from "yup";
import { useUsuarioStore } from "@/store/usuario/usuarioStore";
import IPermissao from "@/interfaces/IPermissao";

export default defineComponent({
  name: "ModalSenhaAssinador",
  inject: ["dialogRef"],
  props: {
    visible: Boolean,
    senha: String,
  },
  components: {},
  data() {
    return {
      senhaAssinador: "",
      // visible: false,
      errors: {
        senhaAssinador: "" as String,
      } as any,
    };
  },
  setup() {
    const schema = Yup.object().shape({
      senhaAssinador: Yup.string().required("A senha é obrigatória"),
    });

    const mensagemStore = useMensagemStore();
    const secretariaStore = useSecretariaStore();
    const usuarioStore = useUsuarioStore();

    return {
      schema,
      mensagemStore,
      secretariaStore,
      usuarioStore,
    };
  },
  computed: {
    // a computed getter

    flag(): boolean {
      var result = this.errors.senhaAssinador === "";
      console.log("d", result);
      return result;
    },
  },
  methods: {
    fechar() {
      console.log("lo", this.fechar);
    },
    validar() {
      // var keys = Object.keys(this.errors);
      // console.log("a", keys);
      // for (var i = 0; i < keys.length; i++) {
      //   this.validate(keys[i]);
      // }
      // console.log("c", this.flag);
      // if (!this.flag) {
      //   // this.loadingButton = false;
      //   console.log("validação NÂO passou", this.senhaAssinador);

      //   return false;
      // }

      console.log("validação passou", this.senhaAssinador);
    },
    validate(field: string) {
      //   this.schema
      //     .validateAt(field, this.senhaAssinador)
      //     .then(() => {
      //       this.errors[field] = "";
      //     })
      //     .catch((err) => {
      //       this.errors[field] = err.message;
      //     });
      //   console.log("b", this.schema);
    },
  },
});
