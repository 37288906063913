import { defineStore } from "pinia";
import axios from "axios";

export const useMensagemStore = defineStore("mensagem", {
  state: () => ({
    listaMensagens: [],
    loading: false,
    filtro: 0,
  }),

  getters: {
    getMensagem(state) {
      return state.listaMensagens;
    },
  },

  actions: {
    async getEncId(usuario_id: any) {
      try {
        this.loading = true;
        const id = usuario_id;
        const data = await axios.get("/obtem-id-encaminhamento/" + id);
        return data.data;
      } catch (error) {}
    },
    async todasMsgForamLidas(usuario_id: any) {
      try {
        this.loading = true;
        const id = usuario_id;
        const data = await axios.get("/caixa-de-entrada/" + id);
        return data.data;
      } catch (error) {}
    },
    async buscarMensagensLeitura(
      data1: any,
      data2: any,
      pagina: any,
      keyword: any,
      setor: any,
      usuario_id: any,
      sec_dest_filtro: any,
      setor_dest_filtro: any
    ) {
      try {
        this.loading = true;
        const id = usuario_id;
        const data = await axios.get(
          "/caixa-de-entrada/" +
            id +
            "/" +
            data1 +
            "/" +
            data2 +
            "/" +
            pagina +
            "/" +
            keyword +
            "/" +
            setor +
            "/" +
            sec_dest_filtro +
            "/" +
            setor_dest_filtro,

          {
            headers: {
              "cache-control": "max-age=60000",
            },
          }
        );
        this.listaMensagens = data.data;
        return data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        alert(error);
      }
    },
    async buscaMensagensEnviadas(
      data1: any,
      data2: any,
      pagina: any,
      keyword: any,
      setor: any,
      usuario_id: any,
      sec_dest_filtro: any,
      setor_dest_filtro: any
    ) {
      try {
        const id = usuario_id;
        const data = await axios.get(
          "mensagens-enviadas/" +
            id +
            "/" +
            data1 +
            "/" +
            data2 +
            "/" +
            pagina +
            "/" +
            keyword +
            "/" +
            setor +
            "/" +
            sec_dest_filtro +
            "/" +
            setor_dest_filtro
        );
        return data.data;
      } catch (error) {}
    },
    async buscaMensagensParaAssinar(
      data1: any,
      data2: any,
      pagina: any,
      keyword: any,
      setor: any,
      modo: any
    ) {
      try {
        const id = sessionStorage.getItem("id");
        const data = await axios.get(
          "mensagens-para-assinar/" +
            id +
            "/" +
            data1 +
            "/" +
            data2 +
            "/" +
            pagina +
            "/" +
            keyword +
            "/" +
            setor +
            "/" +
            modo
        );
        return data.data;
      } catch (error) {}
    },
    async buscarMensagensVencidas(
      data1: any,
      data2: any,
      pagina: any,
      keyword: any,
      setor: any
    ) {
      try {
        this.loading = true;
        const data = await axios.get(
          "/mensagens-vencidas/" +
            data1 +
            "/" +
            data2 +
            "/" +
            pagina +
            "/" +
            keyword +
            "/" +
            setor,
          {
            headers: {
              "cache-control": "max-age=60000",
            },
          }
        );
        this.listaMensagens = data.data;
        return data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async getTodasNaolidasOuPertoDeExpirar() {
      try {
        const id = sessionStorage.getItem("id");
        const data = await axios.get("obtem-mensagens-vencidas-a-vencer/" + id);
        return data.data;
      } catch (error) {}
    },
    async buscaMensagensAprovacaoSecRemetente() {
      try {
        const id = sessionStorage.getItem("id");
        const data = await axios.get(
          "mensagens-para-aprovacao-de-envio-secretaria-destinataria/" + id
        );
        return data.data;
      } catch (error) {}
    },
    async buscaMensagensAprovacaoSecDestinatario() {
      try {
        const id = sessionStorage.getItem("id");
        const data = await axios.get(
          "mensagens-para-aprovacao-de-envio-setor-destinatario/" + id
        );
        return data.data;
      } catch (error) {}
    },
    async getOne(id: any) {
      try {
        const idu = sessionStorage.getItem("id");
        const data = await axios.get(
          "mensagens-visualizacao/" + id + "/" + idu
        );
        return data.data;
      } catch (error) {}
    },

    async obtemUniaoCiEncsAssinar(
      data1: any,
      data2: any,
      pagina: any,
      keyword: any,
      setor: any,
      modo: any
    ) {
      try {
        const id = sessionStorage.getItem("id");
        const data = await axios.get(
          "mensagens-encaminhamentos-para-assinar/" +
            id +
            "/" +
            data1 +
            "/" +
            data2 +
            "/" +
            pagina +
            "/" +
            keyword +
            "/" +
            setor +
            "/" +
            modo
        );
        return data.data;
      } catch (error) {}
    },

    async obtemEncsAssinar(
      id_usuario: any,
      data1: any,
      data2: any,
      pagina: any,
      keyword: any
    ) {
      try {
        const data = await axios.get(
          "/obtem-encaminhamentos-para-assinar/" +
            id_usuario +
            "/" +
            data1 +
            "/" +
            data2 +
            "/" +
            pagina +
            "/" +
            keyword
        );
        return data.data;
      } catch (error) {}
    },

    async assinaEnc(id_enc: any, id_usuario: any) {
      try {
        const data = await axios.get(
          "/assina-encaminhamentos/" + id_usuario + "/" + id_enc
        );
        return data.data;
      } catch (error) {}
    },

    async autenticaEnc(dados: any) {
      try {
        const data = await axios.post(
          "/autentica-encaminhamento/" + dados.id,
          dados
        );
        return data.data;
      } catch (error) {}
    },

    async adicionarResposta(dados: any) {
      try {
        const data = await axios.post("/mensagens", dados);
        return data.data;
      } catch (error) {}
    },

    async mudaPrazo(dados: any) {
      try {
        const data = await axios.put("/mensagens-prazo/" + dados.id, dados);
        return data.data;
      } catch (error) {}
    },

    async assinarTodas(dados: any) {
      try {
        const id = sessionStorage.getItem("id");
        const data = await axios.put("/assina-todas-mensagens/" + id, dados);
        return data.data;
      } catch (error) {}
    },

    async editarMensagem(dados: any) {
      try {
        const data = await axios.put("/mensagens/" + dados.id, dados);
        return data.data;
      } catch (error) {}
    },
    async editarEncaminhamento(dados: any) {
      try {
        const data = await axios.put("/encaminhamentos/" + dados.id, dados);
        return data.data;
      } catch (error) {}
    },
    async excluirMensagem(id: number) {
      try {
        const data = await axios.delete("/mensagens/" + id);
        return data.data;
      } catch (error) {}
    },
    async encerrarMensagem(id: number) {
      try {
        const data = await axios.get("/mensagens-encerrar/" + id);
        return data.data;
      } catch (error) {}
    },

    async enviaParaAprovacao(dados: any) {
      try {
        const data = await axios.put(
          "/enviar-mensagem-para-aprovacao/" + dados.id,
          dados
        );

        return data.data;
      } catch (error) {}
    },

    async enviaParaAprovacaoSecDestinatario(dados: any) {
      try {
        const data = await axios.put(
          "/enviar-mensagem-para-aprovacao-destinatario/" + dados.id,
          dados
        );
        return data.data;
      } catch (error) {}
    },

    async enviaParaSetores(dados: any) {
      try {
        const data = await axios.put(
          "/enviar-mensagem-para-setores/" + dados.id,
          dados
        );
        return data.data;
      } catch (error) {}
    },

    async cadastrarDestinatario(dados: any) {
      try {
        const data = await axios.post("/destinatarios", dados);
        return data.data;
      } catch (error) {}
    },
    async editarDestinatario(dados: any) {
      try {
        const data = await axios.put("/destinatarios/" + dados.id, dados);
        return data.data;
      } catch (error) {}
    },
    async excluirDestinatario(id: number) {
      try {
        const data = await axios.delete("/destinatarios/" + id);
        return data.data;
      } catch (error) {}
    },

    async enviaEncaminhamento(dados: any) {
      try {
        const data = await axios.post("/encaminhamentos", dados);
        return data.data;
      } catch (error) {}
    },

    async getEnc(id: any) {
      try {
        const data = await axios.get("/encaminhamentos/" + id);
        return data.data;
      } catch (error) {}
    },
    async cancelaEncaminhamento(id: number) {
      try {
        const data = await axios.delete("/encaminhamentos/" + id);
        return data.data;
      } catch (error) {}
    },

    async enviaAnexo(formData: any) {
      try {
        const data = await axios.post("/anexos", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        return data.data;
      } catch (error) {}
    },

    async alteraAnexo(dados: any) {
      try {
        const data = await axios.put("/anexos/" + dados.id, dados);
        return data.data;
      } catch (error) {}
    },

    async validaUuidAssinatura(uuid: string) {
      try {
        const data = await axios.get("/valida-uuid-assinatura/" + uuid);
        return data.data;
      } catch (error) {}
    },

    async removeAnexo(id: number) {
      try {
        const data = await axios.delete("/anexos-remocao/" + id + "/mensagem");
        return data.data;
      } catch (error) {}
    },
    async removeAnexoEncaminhamento(id: number) {
      try {
        const data = await axios.delete(
          "/anexos-remocao/" + id + "/encaminhamento"
        );
        return data.data;
      } catch (error) {}
    },

    async obtemCisReservadasDisponiveis() {
      try {
        const data = await axios.get("/obtem-mensagens-reservadas");
        return data.data;
      } catch (error) {}
    },
    async criaReservadas() {
      try {
        const data = await axios.post("/cria-reservadas");
        return data.data;
      } catch (error) {}
    },

    async obtemInteracoes(dados: any, usuario_id: any) {
      try {
        const id_usuario_logado = usuario_id;

        const data = await axios.get(
          "/obtem-interacoes-usuarios/" +
            dados.remetente_id +
            "/" +
            dados.id +
            "/" +
            id_usuario_logado
        );
        return data.data;
      } catch (error) {}
    },

    async getMsgCategorizada(id_usuario: number) {
      try {
        const data = await axios.get(
          "/mensagens-filtro-total-cards/" + id_usuario
        );
        return data.data;
      } catch (error) {}
    },

    async filtroMensagem(tipo_filtro: number, id_usuario: number) {
      try {
        // var id_usuario_logado = sessionStorage.getItem("id");

        const data = await axios.get(
          "/mensagens-filtro/" + tipo_filtro + "/" + id_usuario
        );
        return data.data;
      } catch (error) {}
    },
    async filtroMensagemEnviada(tipo_filtro: number, id_usuario: number) {
      try {
        // var id_usuario_logado = sessionStorage.getItem("id");

        const data = await axios.get(
          "/mensagens-enviadas-filtro/" + tipo_filtro + "/" + id_usuario
        );
        return data.data;
      } catch (error) {}
    },

    //mostrar todas as msg do sistema
    async todasMensagensSistema(
      data1: any,
      data2: any,
      pagina: any,
      keyword: any,
      setor: any
    ) {
      try {
        const id = sessionStorage.getItem("id");
        const data = await axios.get(
          "/lista-todas-mensagens/" +
            id +
            "/" +
            data1 +
            "/" +
            data2 +
            "/" +
            pagina +
            "/" +
            keyword +
            "/" +
            setor
        );
        return data.data;
      } catch (error) {}
    },
  },
});
