import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import DashboardLayout from "@/layout/dashboard.vue";

import { useCriptoStore } from "@/store/store";

const checkAuth = async function (to: any) {
  try {
    if (!window.sessionStorage.getItem("token")) {
      return false;
    }
    const criptoStore = useCriptoStore();
    var permissoes = await criptoStore.decryptBack(
      sessionStorage.getItem("permissao")
    );

    let verificacao = true;
    permissoes = JSON.parse(permissoes);

    if ("hab_" + to.meta.modulo in permissoes) {
      verificacao = permissoes["hab_" + to.meta.modulo];
    }

    return verificacao;
  } catch (error) {}
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "listar-entrada" },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },

  {
    name: "administracao",
    path: "/painel",
    component: DashboardLayout,
    beforeEnter: async (to, from, next) => {
      try {
        return next();
      } catch (e) {
        next("/autenticacao/login");
      }
    },
    children: [
      // Permissoes visualizar
      {
        name: "listar-permissao",
        path: "permissao/listar",
        props: true,
        component: () => import("@/views/admin/permissao/permissao.vue"),
        meta: {
          title: "Gerenciar Permissão",
          modulo: "permissao",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Permissoes adicionar
      {
        name: "adicionar-permissao",
        path: "permissao/cadastrar",
        props: true,
        component: () => import("@/views/admin/permissao/addPermissao.vue"),
        meta: {
          title: "Adicionar Permissão",
          modulo: "permissao",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Permissoes Editar
      {
        name: "editar-permissao",
        path: "permissao/editar/:id",
        props: true,
        component: () => import("@/views/admin/permissao/editarPermissao.vue"),
        meta: {
          title: "Editar Permissão",
          modulo: "permissao",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Usuarios
      // Cadastrar usuario
      {
        name: "cadastrar-usuario",
        path: "usuario/cadastrar",
        props: true,
        component: () => import("@/views/admin/usuarios/cadastrar.vue"),
        meta: {
          title: "Cadastrar Usuario",
          modulo: "usuario",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar usuario
      {
        name: "editar-usuario",
        path: "usuario/editar/:id",
        props: true,
        component: () => import("@/views/admin/usuarios/editar.vue"),
        meta: {
          title: "Editar Usuario",
          modulo: "usuario",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar usuario
      {
        name: "meus-dados-usuario",
        path: "usuario/meus-dados",
        props: true,
        component: () => import("@/views/admin/usuarios/editar-meus-dados.vue"),
        meta: {
          title: "Caixa de Entrada",
          modulo: "mensagens",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Listar de usuario
      {
        name: "listar-usuarios",
        path: "usuarios/listar",
        props: true,
        component: () => import("@/views/admin/usuarios/listar.vue"),
        meta: {
          title: "Gerenciar Usuario",
          modulo: "usuario",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // relatorio de visualizacao
      /*{
        name: "relatorio-visualizacao",
        path: "relatorio/visualizacoes",
        props: true,
        component: () =>
          import("@/views/admin/relatorio/visualizacao-mensagens.vue"),
        meta: {
          title: 'Gerenciar Permissão',
          modulo: 'permissao'
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },*/

      // Listar mensagens enviadas
      {
        name: "listar-enviadas",
        path: "listar/mensagens-enviadas",
        props: true,
        component: () =>
          import("@/views/admin/mensagem/lista-mensagens-enviadas.vue"),
        meta: {
          title: "Mensagens Enviadas",
          modulo: "mensangens",
        },
        beforeEnter: async (to, from, next) => {
          try {
            return next();
            /*if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }*/
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Listar mensagens a assinar
      {
        name: "listar-mensagens-para-assinar",
        path: "listar/mensagens-para-assinar",
        props: true,
        component: () =>
          import("@/views/admin/mensagem/lista-mensagens-assinar.vue"),
        meta: {
          title: "Mensagens para Assinar",
          modulo: "mensangens",
        },
        beforeEnter: async (to, from, next) => {
          try {
            return next();
            /*if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }*/
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Listar relatorios ci vencidas
      {
        name: "listar-relatorios-ci-vencidas",
        path: "listar/relatorios-ci-vencidas",
        props: true,
        component: () =>
          import("@/views/admin/mensagem/listar-relatorio-ci-vencidas.vue"),
        meta: {
          title: "Relatorios CI Vencidas",
          modulo: "relatorio_vencida",
        },
        beforeEnter: async (to, from, next) => {
          try {
            return next();
            /*if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }*/
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Listar encaminhamentos para assinar
      {
        name: "listar-encaminhamentos-para-assinar",
        path: "listar/encaminhamentos-para-assinar",
        props: true,
        component: () =>
          import("@/views/admin/mensagem/lista-encaminhamentos-assinar.vue"),
        meta: {
          title: "Encaminhamentos para Assinar",
          modulo: "mensangens",
        },
        beforeEnter: async (to, from, next) => {
          try {
            return next();
            /*if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }*/
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Listar mensagens caixa de entrada
      {
        name: "listar-entrada",
        path: "listar/caixa-de-entrada",
        props: true,
        component: () =>
          import("@/views/admin/mensagem/lista-mensagens-entrada.vue"),
        meta: {
          title: "Caixa de Entrada",
          modulo: "mensangens",
        },
        beforeEnter: async (to, from, next) => {
          try {
            return next();
            /*if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }*/
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Listar todas as mensagens do sistema
      {
        name: "listar-todas-mensagens",
        path: "listar/todas-mensagens",
        props: true,
        component: () =>
          import("@/views/admin/mensagem/visualizarTodasMensagens.vue"),
        meta: {
          title: "Todas Mensagens",
          modulo: "mensangens",
        },
        beforeEnter: async (to, from, next) => {
          try {
            return next();
            /*if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }*/
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Listar mensagens aprovacao sec destinatario
      // {
      //   name: "listar-aprovacao-sec-dest",
      //   path: "listar/aprovacao-de-envio-secretaria-destinataria",
      //   props: true,
      //   component: () =>
      //     import(
      //       "@/views/admin/mensagem/lista-mensagens-aprovacao-sec-destinatario.vue"
      //     ),
      //   beforeEnter: async (to, from, next) => {
      //     try {
      //       return next();
      //     } catch (e) {
      //       next("/autenticacao/login");
      //     }
      //   },
      // },

      // Listar mensagens aprovacao setores
      // {
      //   name: "listar-aprovacao-setores",
      //   path: "listar/aprovacao-de-envio-setores",
      //   props: true,
      //   component: () =>
      //     import(
      //       "@/views/admin/mensagem/lista-mensagens-aprovacao-setores.vue"
      //     ),
      //   beforeEnter: async (to, from, next) => {
      //     try {
      //       return next();
      //     } catch (e) {
      //       next("/autenticacao/login");
      //     }
      //   },
      // },

      // Nova mensagem
      {
        name: "nova-mensagem",
        path: "mensagens/nova",
        props: true,
        component: () => import("@/views/admin/mensagem/novaMensagem.vue"),
        meta: {
          title: "Nova Mensagem",
          modulo: "mensagens",
        },
        beforeEnter: async (to, from, next) => {
          try {
            return next();
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      /*{
        name: "editar-mensagem",
        path: "mensagem/editar/:id",
        props: true,
        component: () => import("@/views/admin/mensagem/editar.vue"),
        meta: {
          title: '',
          modulo: ''
        },
        beforeEnter: async (to, from, next) => {
          try {
            if(await checkAuth(to)){
              return next();
            }else{
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },*/
      {
        name: "visualizar-mensagem",
        path: "mensagem/visualizar/:id",
        props: true,
        component: () => import("@/views/admin/mensagem/visualizar.vue"),
        meta: {
          title: "Visualizar Mensagens",
          modulo: "envio",
        },
        beforeEnter: async (to, from, next) => {
          try {
            //return next();
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Secretarias
      // Lista de Secretarias
      {
        name: "lista-de-secretarias",
        path: "secretarias/listar",
        props: true,
        component: () => import("@/views/admin/secretaria/secretariaALL.vue"),
        meta: {
          title: "Gerenciar Secretaria",
          modulo: "secretaria",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Cadastrar de secretaria
      {
        name: "cadastrar-secretaria",
        path: "secretaria/cadastrar",
        props: true,
        component: () =>
          import("@/views/admin/secretaria/cadastrarSecretaria.vue"),
        meta: {
          title: "Cadastrar Secretaria",
          modulo: "secretaria",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar de secretaria
      {
        name: "editar-secretaria",
        path: "secretaria/editar/:id",
        props: true,
        component: () =>
          import("@/views/admin/secretaria/editarSecretaria.vue"),
        meta: {
          title: "Editar Secretaria",
          modulo: "secretaria",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },

      // Setor
      // Lista de Setores
      {
        name: "lista-de-setores",
        path: "setores/listar",
        props: true,
        component: () => import("@/views/admin/setor/setorAll.vue"),
        meta: {
          title: "Gerenciar Setor",
          modulo: "setor",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Cadastrar Setores
      {
        name: "cadastrar-setor",
        path: "setores/cadastrar",
        props: true,
        component: () => import("@/views/admin/setor/cadastrarSetor.vue"),
        meta: {
          title: "Editar Setor",
          modulo: "setor",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
      // Editar Setores
      {
        name: "editar-setor",
        path: "setores/editar/:id",
        props: true,
        component: () => import("@/views/admin/setor/editarSetor.vue"),
        meta: {
          title: "Editar Setor",
          modulo: "setor",
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (await checkAuth(to)) {
              return next();
            } else {
              next("/autenticacao/login");
            }
          } catch (e) {
            next("/autenticacao/login");
          }
        },
      },
    ],
  },
  {
    name: "login",
    path: "/autenticacao/login",
    component: () => import("@/views/auth/login.vue"),
    meta: {
      titulo: "Autenticação",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
