<template>
  <section>
    <NavbarComponent
      class="sizeDashBoard"
      @ajustarSize="ajustarSize"
      @click="fecharSideBarMobile"
      :isCollapsed="isCollapsed"
    />
    <SidebarComponent @ajustarSize="ajustarSize" />
    <div class="sizeDashBoard" @click="fecharSideBarMobile">
      <router-view />
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import NavbarComponent from "@/components/navbar/NavbarComponent.vue";
import SidebarComponent from "@/components/sidebar/SidebarComponent.vue";

export default defineComponent({
  name: "layout-dashboard",
  components: {
    NavbarComponent,
    SidebarComponent,
  },
  data() {
    return {
      sideBarSize: "250px",
      flag: false,
      isCollapsed: false,
      tamanhoTela: 0,
    };
  },
  setup() {
    // return { sidebarWidth };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    if (this.width > 900) {
      this.justifyContent = "justify-content-end";
    } else {
      this.justifyContent = "";
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.tamanhoTela = window.innerWidth;
    },
    fecharSideBarMobile() {
      if (this.flag) {
        //se for true, fechar a sidebar
        this.flag = !this.flag;
        let width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;

        if (width <= 800) {
          this.closeSideBar();
        }
        return false;
      } else {
        //cada vez que ele clicar ou na dashboard ou na navbar, trocar o estado da flag
        this.flag = !this.flag;
      }
    },
    closeSideBar() {
      document.getElementById("hideShowSideBar").style.display = "none";
      this.ajustarSize("0px");
    },
    ajustarSize(size) {
      this.verificarTamanhoTela();
      this.sideBarSize = size;
    },
    verificarTamanhoTela() {
      if (this.tamanhoTela > 800) {
        this.isCollapsed = !this.isCollapsed;
      }
    },
  },
  beforeMount() {
    if (this.tamanhoTela <= 800) {
      this.isCollapsed = true;
    }
    if (sessionStorage.getItem("token") == null) {
      location.href = "/autenticacao/login";
    }
  },
});
</script>

<style>
.sizeDashBoard {
  margin-left: v-bind(sideBarSize);
  transition: 0.3s ease;
}

@media only screen and (max-width: 800px) {
  .sizeDashBoard {
    margin-left: 2px;
  }
}
</style>
