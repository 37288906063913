<template>
  <div id="noprint" >
    <router-view />
  </div>

  <div id="printme"></div>
  
</template>

<style></style>
